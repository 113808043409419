/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("./src/styles/index.scss")

exports.onClientEntry = () => {
  ;(function () {
    var zi = document.createElement("script")
    zi.type = "text/javascript"
    zi.async = true
    zi.referrerPolicy = "unsafe-url"
    zi.src = "https://ws.zoominfo.com/pixel/620e93a614de72001cc2aa77"
    var s = document.getElementsByTagName("script")[0]
    s.parentNode.insertBefore(zi, s)
  })()
}
